import {
  Component,
  ViewEncapsulation,
  OnInit,
  EventEmitter,
  Output,
} from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router } from "@angular/router";

// Services
import { LanguageService } from "./services/language.service";
import { ThemeService } from "./services/theme.service";
import { StorageService } from "./services/storage.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  @Output() sidenavClose = new EventEmitter();
  darkMode: boolean;
  public language: string = this.languageService.selected;
  public appPages = [
    {
      title: "Médicaments",
      titlefr: "الأدوية",
      url: "/app/tabs/medicaments-list",
      icon: "link",
      menuIcon: "menuIconCountry",
    },
    {
      title: "Pharmacies de garde",
      titlefr: "صيدليات المداومة",
      url: "/app/tabs/garde-list",
      icon: "calendar",
      menuIcon: "menuIconOceans",
    },

    {
      title: "Qui sommes-nous",
      titlefr: "من نحن",
      url: "/app/tabs/about",
      icon: "information-circle-outline",
      menuIcon: "menuIconAbout",
    },
  ];

  constructor(
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public themeService: ThemeService,
    private languageService: LanguageService,
    private storageService: StorageService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.languageService.setInitialAppLanguage();
      this.darkStartMode();
    });
  }

  async darkStartMode() {
    this.storageService.getStoredData("dark-theme").then((val) => {
      this.darkMode = JSON.parse(val);
      this.darkMode === true
        ? this.themeService.enableDark()
        : this.themeService.enableLight();  
    });
  }

  languageChange() {
    this.languageService.setLanguage(this.language);
  }

  onSidenavClose = () => {
    this.sidenavClose.emit();
  };
}
